




import { Component, Vue } from 'vue-property-decorator'
import RecommendationDetail from '../../components/RecommendationDetail.vue'

@Component({
  name: 'EditProperty',
  components: {
    RecommendationDetail
  }
})
export default class extends Vue {}
